import React, { createContext, useReducer, useEffect, useCallback } from 'react';
import ACTION from './actions';
import walletReduce from './reducer';
import { useHistory } from 'react-router-dom';
import { Wallet } from 'src/utils/blockchain/wallet';
import { DataService } from 'src/service/db';
import hash from 'object-hash';
const initialState = {
	wallet: null,
	hasWallet: false,
	walletPasscode: null,
	loading: false,
	openPasscodeModal: false,
};

export const WalletContext = createContext(initialState);
export const WalletContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(walletReduce, initialState);
	const history = useHistory();

	const setWallet = useCallback(
		(wallet) => {
			dispatch({ type: ACTION.SET_WALLET, data: wallet });
			dispatch({ type: ACTION.SET_HASWALLET, data: true });
		},
		[dispatch],
	);
	const setLoading = useCallback(
		(flag) => {
			dispatch({ type: ACTION.SET_LOADING, data: flag });
		},
		[dispatch],
	);

	const unlockWallet = useCallback(async () => {
		try {
			const passcode = await DataService.get('passcode');
			const encryptedWallet = await DataService.get('wallet');
			if (!passcode || !encryptedWallet) throw Error('No wallet found!');
			const decryptedWallet = await Wallet.loadFromJson(passcode, encryptedWallet);
			setWallet(decryptedWallet);
			return decryptedWallet;
		} catch (err) {
			throw err;
		}
	}, [setWallet]);

	const initApp = useCallback(async () => {
		dispatch({ type: ACTION.SET_LOADING, data: true });
		let data = await DataService.initAppData();
		data.hasWallet = data.wallet === null ? false : true;
		if (!data.hasWallet) localStorage.removeItem('address');
		if (data.hasWallet) await unlockWallet();
		dispatch({ type: ACTION.INIT_APP, data });
		dispatch({ type: ACTION.SET_LOADING, data: false });
	}, [dispatch, unlockWallet]);

	function setPasscodeModal(flag) {
		dispatch({ type: ACTION.SET_PASSCODE_MODAL, data: flag });
	}

	function setHasWallet(hasWallet) {
		dispatch({ type: ACTION.SET_HASWALLET, data: hasWallet });
	}

	function setWalletPasscode(passcode) {
		dispatch({ type: ACTION.SET_APP_PASSCODE, data: passcode });
	}

	async function signAuthSignature(wallet) {
		let timeStamp = Date.now();
		let signed = await wallet.signMessage(timeStamp.toString());
		const signature = timeStamp + '.' + signed;
		return signature;
	}

	async function signDataSignature(wallet, data = {}) {
		const mdHash = hash.MD5(data);
		let signed = await wallet.signMessage(mdHash);
		const signature = mdHash + '.' + signed;
		return signature;
	}

	const getAuthAndDataSignature = useCallback(
		async (payload) => {
			let { wallet } = state;
			let data_signature = null;
			if (!wallet) wallet = await unlockWallet();
			const auth_signature = await signAuthSignature(wallet);
			if (payload) data_signature = await signDataSignature(wallet, payload);
			return { auth_signature, data_signature };
		},
		[state, unlockWallet],
	);

	async function signAndCallService(passedFunc, passedParams) {
		let wallet = state.wallet;
		let hasWallet = state.hasWallet;
		let payload;
		try {
			if (hasWallet && !wallet) {
				wallet = await unlockWallet();
			} else if (!hasWallet) {
				history.push('/register');
				return;
			}
			let auth_signature = await signAuthSignature(wallet);
			let data_signature;
			if (passedParams) {
				data_signature = await signDataSignature(wallet, passedParams);
				payload = {
					...passedParams,
					auth_signature,
					data_signature,
				};
			} else {
				payload = {
					...passedParams,
					auth_signature,
				};
			}

			return passedFunc(payload);
		} catch (err) {
			throw err;
		}
	}

	useEffect(() => {
		initApp();

		return () => {};
	}, [initApp]);

	return (
		<WalletContext.Provider
			value={{
				initApp,
				setWalletPasscode,
				setWallet,
				setHasWallet,
				setLoading,
				setPasscodeModal,
				signAndCallService,
				getAuthAndDataSignature,
				hasWallet: state.hasWallet,
				loading: state.loading,
				walletPasscode: state.walletPasscode,
				wallet: state.wallet,
			}}
		>
			{children}
		</WalletContext.Provider>
	);
};
